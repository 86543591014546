import { FormattedMessage } from "react-intl";
import { IconPhone } from "@tabler/icons-react";

import { IonContent, IonModal } from "@ionic/react";

import styles from "./styles.module.css";

interface Properties {
  isOpen: boolean;
  //onWillDismiss: () => void;
  isDialing: boolean;
  isConnected: boolean;
  onHangUp: Function;
  clientCanvasRef: React.RefObject<HTMLCanvasElement>;
  serverCanvasRef: React.RefObject<HTMLCanvasElement>;
  displayName: string;
}

const CallModal = ({
  isOpen,
  onHangUp,
  isDialing,
  isConnected,
  clientCanvasRef,
  serverCanvasRef,
  displayName,
}: Properties) => {
  if (!isOpen) return null;
  return (
    <IonModal isOpen={isOpen} onWillDismiss={() => onHangUp()}>
      <IonContent>
        <div className={styles.callContainer}>
          <div className={styles.callContactInfo}>
            <div className="fs-6 fw-bold">{displayName}</div>

            <div className="fs-3">
              {isConnected ? (
                <FormattedMessage id="aiSettingsCallConnected" />
              ) : (
                <>
                  <FormattedMessage id="aiSettingsCalling" /> ...
                </>
              )}
            </div>

            <div className={styles.visualization}>
              <div className="visualization-entry client">
                <canvas ref={clientCanvasRef} />
              </div>
              <div className="visualization-entry server">
                <canvas ref={serverCanvasRef} />
              </div>
            </div>
          </div>
          <div className={styles.callActions}>
            <button
              className="btn btn-visito-danger round-btn icon"
              onClick={() => onHangUp()}
            >
              <IconPhone
                size={20}
                style={{
                  transform: `rotate(135deg) translate(1px,-1px)`,
                }}
              />
            </button>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default CallModal;
